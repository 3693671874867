<template>
    <a-modal
        v-model:visible="visible"
        title="员工列表"
        width="1000px"
        okText="确定"
        :destroyOnClose="true"
        @cancel="handleCancel"
        @ok="handleSubmit"
    >
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="params.jobNo" placeholder="请输入工号" />
            <a-input v-model:value="params.displayName" placeholder="请输入姓名" />
            <DeptSelect v-model:value="params.deptId" />
            <a-button type="primary" @click="handleSearch">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>

        <a-table
            :columns="columns"
            :data-source="tableData"
            :loading="loading"
            bordered
            size="small"
            :pagination="pagination"
            @change="paginationChange"
            :row-selection="{
                selectedRowKeys,
                onChange: onSelectChange,
                type: selectType,
                getCheckboxProps: record => ({
                    disabled: disableData.map(item => item.id).includes(record.id),
                }),
            }"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                </template>
                <template v-if="column.dataIndex === 'deptInfos'">
                    <div v-for="(item, index) in record.deptInfos" :key="index">
                        <a-tag :style="{ opacity: item.main ? 1 : 0 }" color="green">主</a-tag>
                        {{ item.sysDept?.name }} - {{ item.sysPost?.name }}
                    </div>
                </template>
                <!-- <template v-if="column.dataIndex === 'roles'">
                    <div v-for="item in record.roles" :key="item.id">{{ item.name }},</div>
                </template> -->
            </template>
        </a-table>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiUserList } from "@/api";
import Select from "@/components/Select";
import DeptSelect from "../DeptSelect";
import { showMessage } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 80,
    },
    {
        title: "工号",
        dataIndex: "jobNo",
    },
    {
        title: "姓名",
        dataIndex: "displayName",
    },
    {
        title: "部门-岗位",
        dataIndex: "deptInfos",
        width: 350,
    },
    {
        title: "手机号",
        dataIndex: "cellphone",
    },
];

export default defineComponent({
    props: {
        userListVisible: {
            default: false,
        },
        selectType: {
            default: "checkbox", // radio
        },
        apiParams: {
            default: "",
        },
        disableData: {
            default: [],
        },
    },
    emits: ["update:userListVisible", "onSelect"],
    components: {
        Select,
        DeptSelect,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            name: "",
            tableData: [],
            selectedRowKeys: [],
            selectedRows: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
            },
            params: {
                jobNo: "",
                displayName: "",
                deptId: "",
                enabled: true,
            },
        });

        const handleCancel = () => {
            state.pagination.current = 1;
            state.visible = false;
            state.selectedRowKeys = [];
            state.selectedRows = [];
            emit("update:userListVisible", false);
        };

        const getTabelData = async () => {
            state.loading = true;
            const {
                pagination: { current: pageNum, pageSize },
                params: { jobNo, displayName, deptId },
            } = state;
            const data = {
                pageNum,
                pageSize,
                queryParam: {
                    jobNo: jobNo?.trim(),
                    displayName: displayName?.trim(),
                    deptId,
                },
            };
            let res;
            if (props.apiParams) {
                res = await props.apiParams.api({
                    id: props?.apiParams?.id,
                    data,
                });
            } else {
                res = await apiUserList(data);
            }
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.tableData = res.data.recordList.map((item, index) => ({
                    ...item,
                    key: index,
                }));
            }
        };

        const reset = () => {
            state.params.jobNo = undefined;
            state.params.displayName = undefined;
            state.params.deptId = undefined;
            getTabelData();
        };

        const handleSearch = () => {
            state.pagination.current = 1;
            getTabelData();
        };

        const paginationChange = pagination => {
            state.selectedRowKeys = [];
            state.selectedRows = [];
            state.pagination = pagination;
            getTabelData();
        };

        const onSelectChange = (selectedRowKeys, selectedRows) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
        };

        const handleSubmit = async () => {
            if (!state.selectedRows.length) {
                return showMessage("info", "请先选择员工");
            }
            emit("onSelect", state.selectedRows);
            handleCancel();
        };

        watch(
            () => props.userListVisible,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    state.params.jobNo = undefined;
                    state.params.displayName = undefined;
                    state.params.deptId = undefined;
                    getTabelData();
                }
            }
        );

        return {
            columns,
            ...toRefs(state),
            handleCancel,
            paginationChange,
            getTabelData,
            handleSubmit,
            onSelectChange,
            handleSearch,
            reset,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-space {
    margin-right: 16px;
    margin-bottom: 16px;
    .ant-input,
    .ant-select {
        width: 160px;
    }
}
</style>
