<template>
    <a-modal
        v-model:visible="visible"
        :title="`数据字典-${typeName}类别`"
        width="1000px"
        okText="确定"
        :destroyOnClose="true"
        @cancel="handleCancel"
        @ok="handleSubmit"
    >
        <a-table
            :columns="columns"
            :data-source="
                data.map((item, index) => ({
                    ...item,
                    key: index,
                    children: '',
                }))
            "
            :loading="loading"
            bordered
            size="small"
            :row-selection="{
                selectedRowKeys,
                onChange: onSelect,
                type: 'radio',
            }"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                </template>
            </template>
        </a-table>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { showMessage } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 80,
    },
    {
        title: "类别编码",
        dataIndex: "code",
    },
    {
        title: "类别名称",
        dataIndex: "name",
    },
];

export default defineComponent({
    props: {
        categoryVisible: {
            default: false,
        },
        data: {
            default: [],
        },
        typeName: {
            default: "",
        },
    },
    emits: ["update:categoryVisible", "onSelect"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            tableData: [],
            selectedRowKeys: [],
            selectedRows: [],
            keyWords: "",
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
            },
        });

        const handleCancel = () => {
            state.pagination.current = 1;
            state.visible = false;
            state.selectedRowKeys = [];
            state.selectedRows = [];
            emit("update:categoryVisible", false);
        };

        const onSelect = (selectedRowKeys, selectedRows) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
        };

        const handleSubmit = async () => {
            if (!state.selectedRows.length) {
                return showMessage("info", `请先选择${props.typeName}类别`);
            }
            emit("onSelect", state.selectedRows);
            handleCancel();
        };

        watch(
            () => props.categoryVisible,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    state.keyWords = "";
                }
            }
        );

        return {
            columns,
            ...toRefs(state),
            handleCancel,

            handleSubmit,
            onSelect,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-space {
    margin-right: 16px;
    margin-bottom: 16px;
    .ant-input,
    .ant-select {
        width: 160px;
    }
}
</style>
