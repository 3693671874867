<template>
    <a-upload
        :accept="accept"
        v-model:file-list="fileList"
        name="file"
        :showUploadList="false"
        :action="`${fileURL}/upload2?businessType=${businessType}`"
        :headers="headers"
        :beforeUpload="beforeUpload"
        @change="handleChange"
    >
        <a-button :disabled="disabled">
            <upload-outlined></upload-outlined>
            选择文件
        </a-button>
    </a-upload>
    <div class="file-item" v-for="(item, index) in files">
        <a :href="`${fileURL}/download/${item?.id}`" class="name">
            {{ item?.fileName }}
        </a>
        <close-outlined class="delete" v-show="!disabled" @click="handleDelete(index)" />
    </div>
</template>
<script>
import { fileURL } from "@/utils/config";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref } from "vue";
import { baseURL } from "@/utils/config";
import { useStore } from "vuex";
import { showMessage } from "@/utils/common";
export default defineComponent({
    components: {
        UploadOutlined,
        CloseOutlined,
    },
    props: {
        businessType: {
            default: "REPORT_WORK_LIVE_PICTURE",
        },
        accept: {
            default: "",
        },
        files: {
            default: [],
        },
        disabled: {
            default: false,
        },
    },
    emits: ["onSuccess", "deleteFile"],
    setup(props, { emit }) {
        const { getters } = useStore();
        const name = ref("");

        const beforeUpload = file => {
            // const lastName = file.name.substring(file.name.lastIndexOf(".") + 1);
            // const notAllowedNames = ["jpg"];
            // if (notAllowedNames.includes(lastName)) {
            //     showMessage("info", `.${lastName}后缀名文件禁止上传`);
            //     return false;
            // }
        };

        const handleChange = res => {
            if (res?.file?.status == "done") {
                name.value = res?.file?.name;
                showMessage("success", "上传成功");
                emit("onSuccess", res?.file.response?.data);
            }
        };

        function handleDelete(index) {
            emit("deleteFile", index);
        }

        const fileList = ref([]);
        return {
            fileList,
            name,
            handleChange,
            baseURL,
            headers: {
                "mes-token": getters["user/token"],
                // "context-type": "multipart/form-data",
            },
            fileURL,
            handleDelete,
            beforeUpload,
        };
    },
});
</script>

<style lang="less" scoped>
.file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    margin-top: 5px;
    .name {
        width: 100%;
    }
    .delete {
        cursor: pointer;
    }
}
</style>
