<template>
    <DeviceCategory
        :data="deviceCategoryList"
        v-model:categoryVisible="processDeviceVisible"
        typeName="设备"
        @onSelect="onChangeDeviceCategory"
    />
    <UserList
        v-model:userListVisible="userListVisible"
        selectType="radio"
        @onSelect="onChangeFzr"
    />
    <a-modal
        v-model:visible="visible"
        :title="`${upDateId ? '编辑' : '新增'}设备`"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="700px"
    >
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="设备编码"
                name="code"
                :rules="[
                    { validator: checkAllSpace },
                    { required: true, message: '请输入设备编码' },
                ]"
            >
                <a-input
                    v-model:value="formData.code"
                    :maxlength="100"
                    showCount
                    :disabled="Boolean(upDateId)"
                />
            </a-form-item>

            <a-form-item
                label="设备名称"
                name="name"
                :rules="[
                    { required: true, message: '请输入设备名称' },
                    { validator: checkAllSpace },
                ]"
            >
                <a-input v-model:value="formData.name" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item label="设备类别" name="categoryName">
                <a-form-item>
                    <SelectInput
                        :title="formData.categoryName"
                        :onClick="() => (processDeviceVisible = true)"
                        @onDelete="() => onChangeDeviceCategory()"
                    ></SelectInput>
                </a-form-item>
            </a-form-item>

            <a-form-item
                label="设备状态"
                name="status"
                :rules="[{ required: true, message: '请选择设备状态' }]"
            >
                <Select
                    v-model:value="formData.status"
                    :options="deviceStatusOptions"
                    labelKey="name"
                    valueKey="code"
                />
            </a-form-item>

            <a-form-item label="规格型号" name="spec" :rules="[{ validator: checkAllSpace }]">
                <a-input v-model:value="formData.spec" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item label="品牌" name="brand" :rules="[{ validator: checkAllSpace }]">
                <a-input v-model:value="formData.brand" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="出厂编号"
                name="factoryNumber"
                :rules="[{ validator: checkAllSpace }]"
            >
                <a-input v-model:value="formData.factoryNumber" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item label="使用部门" name="usedDeptCode">
                <DeptSelect
                    v-model:value="formData.usedDeptCode"
                    valueKey="code"
                    :useLable="true"
                    @onChange="onChangeDept"
                />
            </a-form-item>

            <a-form-item label="负责人" name="fzr" :rules="[{ validator: checkAllSpace }]">
                <a-form-item>
                    <SelectInput
                        :title="formData.fzr"
                        :onClick="() => (userListVisible = true)"
                        @onDelete="() => onChangeFzr()"
                    ></SelectInput>
                </a-form-item>
            </a-form-item>

            <a-form-item label="投用日期" name="commissioningDate">
                <a-date-picker
                    v-model:value="formData.commissioningDate"
                    valueFormat="YYYY-MM-DD"
                />
            </a-form-item>

            <a-form-item label="备注" name="remark" :rules="[{ validator: checkAllSpace }]">
                <a-textarea
                    v-model:value="formData.remark"
                    placeholder="最多可输入800字"
                    :rows="4"
                    :maxlength="800"
                />
            </a-form-item>

            <a-form-item label="设备附件" name="fileIds">
                <UploadFile
                    businessType="BAS_DEVICE_FILE"
                    :files="formData.fileIds"
                    @onSuccess="fileInfo => onSuccess(fileInfo)"
                    @deleteFile="index => onDeleteFile(index)"
                ></UploadFile>
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiUpdateDevice, apiUpdateDeviceIndexData } from "@/api";
import Select from "@/components/Select";
import DeviceCategory from "@/components/CategoryModal";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import UploadFile from "@/components/UploadFile";
import UserList from "@/components/UserList";
import DeptSelect from "@/components/DeptSelect";
import SelectInput from "@/components/SelectInput";
export default defineComponent({
    props: ["upDateId"],
    emits: ["eventUpdateOk"],
    components: {
        Select,
        DeviceCategory,
        DownOutlined,
        UploadFile,
        UserList,
        DeptSelect,
        SelectInput,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            formRef: null,
            userListVisible: false,
            deviceCategoryList: [],
            processDeviceVisible: false,
            deviceStatusOptions: [],
            deptsOptions: [],
            formData: {
                id: "",
                name: "",
                code: "",
                categoryCode: "",
                categoryName: "",
                status: "",
                spec: "",
                brand: "",
                factoryNumber: "",
                usedDept: "",
                usedDeptCode: "",
                fzr: "",
                commissioningDate: "",
                remark: "",
                fileIds: [],
            },
        });

        const handleCancel = () => {
            state.visible = false;
            state.formRef.resetFields();
            emit("update:upDateId", null);
        };

        const onFinish = async () => {
            let fileIds = state.formData.fileIds.map(item => item?.id);
            let res = await apiUpdateDevice({
                ...state.formData,
                fileIds,
            });
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const getIndexData = async id => {
            let res = await apiUpdateDeviceIndexData(id);
            if (res.status === "SUCCESS") {
                const { statusCandidate, categoryCandidate, toBeEditData } = res?.data;
                state.deviceStatusOptions = statusCandidate;
                state.deviceCategoryList = categoryCandidate;
                if (id) {
                    Object.keys(state.formData).forEach(key => {
                        state.formData[key] = toBeEditData ? toBeEditData?.[key] : "";
                    });
                    state.formData.fileIds = toBeEditData?.files || [];
                }
            }
        };

        const checkAllSpace = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const onChangeDeviceCategory = arr => {
            const [value] = arr || [];
            state.formData.categoryName = value ? value?.name : "";
            state.formData.categoryCode = value ? value?.code : "";
        };

        const onSuccess = fileInfo => {
            state.formData.fileIds = [...state.formData.fileIds, fileInfo];
        };

        const onDeleteFile = index => {
            state.formData.fileIds.splice(index, 1);
        };

        const onChangeFzr = users => {
            state.formData.fzr = users?.[0]?.displayName || "";
        };

        const onChangeDept = (value, label) => {
            state.formData.usedDeptCode = value;
            state.formData.usedDept = label;
        };

        watch(
            () => props.upDateId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    getIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkAllSpace,
            onChangeDeviceCategory,
            onSuccess,
            onDeleteFile,
            onChangeFzr,
            onChangeDept,
        };
    },
});
</script>

<style lang="less" scoped>
.contant-item {
    display: flex;
    justify-content: space-between;
}
</style>
