<template>
    <a-tree-select
        :value="deptId"
        style="min-width: 250px"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        allow-clear
        tree-default-expand-all
        :field-names="{
            label: 'name',
            value: valueKey,
        }"
        :tree-data="deptData"
        placeholder="请选择部门"
        @change="change"
    ></a-tree-select>
</template>

<script>
import { defineComponent, reactive, toRefs, computed } from "vue";
import { apiDeptaTree } from "@/api";
export default defineComponent({
    props: {
        value: {
            default: "",
        },
        valueKey: {
            default: "id",
        },
        useLable: {
            default: false,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            deptId: computed(() => props.value || undefined),
            deptData: [],
        });
        const getDeptTreeData = async () => {
            let res = await apiDeptaTree();
            if (res.status === "SUCCESS") {
                state.deptData = [res.data];
            }
        };
        getDeptTreeData();

        const change = (value, label) => {
            if (props?.useLable) {
                emit("onChange", value, label[0]);
            } else {
                emit("update:value", value);
            }
        };

        return {
            ...toRefs(state),
            change,
        };
    },
});
</script>
