<template>
    <div :style="{ position: 'relative', top: '-15px', ...style }">
        <div
            :class="['my-input-mask ', disabled ? 'disabled' : '']"
            @click="disabled ? () => {} : onClick()"
        >
            {{ title }}
        </div>
        <close-circle-outlined
            class="my-input-mask-close"
            v-if="showDeleteIcon"
            @click="handleDelete"
        />
    </div>
</template>

<script>
import { computed, defineComponent, watch } from "vue";
import { CloseCircleOutlined } from "@ant-design/icons-vue";
export default defineComponent({
    props: {
        title: {
            default: "",
        },
        disabled: {
            default: false,
        },
        showDeleteIcon: {
            default: true,
        },
        style: {
            default: () => {},
        },
        onClick: {
            default: () => {},
        },
    },
    emits: ["onDelete"],
    components: {
        CloseCircleOutlined,
    },
    setup(props, { emit }) {
        const handleDelete = () => {
            if (props.disabled) return;
            emit("onDelete");
        };

        return {
            handleDelete,
        };
    },
});
</script>
