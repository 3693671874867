<template>
    <a-select
        :mode="mode"
        v-model:value="selectValue"
        @change="handleChange"
        :placeholder="placeholder"
        allow-clear
        :label-in-value="labelInValue"
    >
        <a-select-option
            v-for="(item, index) in options"
            :value="item[valueKey]"
            :key="index"
            :label="item[labelKey]"
            :disabled="item.disabled"
        >
            {{ item[labelKey] }}
        </a-select-option>
    </a-select>
</template>

<script>
import { computed, defineComponent, watch } from "vue";

export default defineComponent({
    props: {
        mode: {
            default: "",
        },
        labelKey: {
            default: "label",
        },
        valueKey: {
            default: "value",
        },
        value: [String, Number, Boolean, Array, Object],
        options: {
            default: [],
        },
        placeholder: {
            default: "",
        },
        labelInValue: {
            default: false,
        },
    },
    setup(props, { emit }) {
        const selectValue = computed(() => props.value);

        const handleChange = value => {
            if (props.labelInValue) {
                value.label = value?.option?.label;
            }
            emit("update:value", value);
        };

        // const setOptions = computed(() => {
        //     const map = new Map();
        //     if (!props?.options?.length) return [];
        //     return props?.options
        //         ?.reverse()
        //         .filter(item => {
        //             if (!item["key"]) return item;
        //             return !map?.has(item["key"] + "") && map?.set(item["key"] + "", 1);
        //         })
        //         ?.reverse();
        // });

        return {
            selectValue,
            handleChange,
            // setOptions,
        };
    },
});
</script>

<style lang="less" scoped>
.pagination {
    position: absolute;
    top: 10px;
    z-index: 111;
}
</style>
