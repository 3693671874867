<template>
    <a-drawer
        v-model:visible="visible"
        :maskClosable="false"
        title="设备详情"
        :closable="false"
        @close="handleCancel"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions title="" :column="2">
            <a-descriptions-item label="设备编码">
                {{ detailData?.code }}
            </a-descriptions-item>
            <a-descriptions-item label="设备名称">
                {{ detailData?.name }}
            </a-descriptions-item>
            <a-descriptions-item label="设备状态">
                {{ detailData?.statusZh }}
            </a-descriptions-item>
            <a-descriptions-item label="设备类别">
                {{ detailData?.categoryName }}
            </a-descriptions-item>
            <a-descriptions-item label="规格型号">
                {{ detailData?.spec }}
            </a-descriptions-item>
            <a-descriptions-item label="品牌">
                {{ detailData?.brand }}
            </a-descriptions-item>
            <a-descriptions-item label="出厂编号">
                {{ detailData?.factoryNumber }}
            </a-descriptions-item>
            <a-descriptions-item label="使用部门">
                {{ detailData?.usedDept }}
            </a-descriptions-item>
            <a-descriptions-item label="负责人">
                {{ detailData?.fzr }}
            </a-descriptions-item>
            <a-descriptions-item label="投用日期">
                {{ detailData?.commissioningDate }}
            </a-descriptions-item>
            <a-descriptions-item label="备注" :span="2">
                {{ detailData?.remark }}
            </a-descriptions-item>
            <a-descriptions-item label="设备附件" :span="2">
                <div class="filebox">
                    <a v-for="item in detailData?.files" :href="`${fileURL}/download/${item?.id}`">
                        {{ item?.fileName }}
                    </a>
                </div>
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
                {{ detailData?.createdTime }}
            </a-descriptions-item>
            <a-descriptions-item label="创建人">
                {{ detailData?.creatorDisplayName }}
            </a-descriptions-item>
            <a-descriptions-item label="上次变更时间">
                {{ detailData?.lastUpdatedTime }}
            </a-descriptions-item>
            <a-descriptions-item label="变更人">
                {{ detailData?.lastUpdaterDisplayName }}
            </a-descriptions-item>
        </a-descriptions>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs, computed } from "vue";
import { apiDeviceGetInfo } from "@/api";
import { fileURL } from "@/utils/config";

export default defineComponent({
    props: ["detailId"],
    emits: ["update:detailId"],
    setup(props, { emit }) {
        const state = reactive({
            loading: false,
            visible: false,
            detailData: null,
            tableData: [],
        });

        const handleCancel = () => {
            emit("update:detailId");
        };

        const getDetailData = async id => {
            let res = await apiDeviceGetInfo(id);
            if (res.status === "SUCCESS") {
                state.detailData = res.data;
            }
        };

        watch(
            () => props.detailId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getDetailData(newVal);
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            fileURL,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
    }
}
</style>
